import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'

export default function useSigninSSO () {
	const getSigninSSOurl = (redirectUri: string) => {
		const config = useRuntimeConfig()
		const authStore = useAuthStore()
		const { authBody } = storeToRefs(authStore)
		const url = new URL(config.public.baseUrl.sso)
		const params = {
			client_id: config.public.clientId,
			response_type: 'code',
			scope: 'openid profile email address phone',
			state: authBody.value?.deviceId,
		}

		if (redirectUri) {
			params.redirect_uri = encodeURI(redirectUri)
		}

		Object.keys(params).forEach(key => {
			url.searchParams.set(key, params[key])
		})

		return url.toString()
	}

	return {
		getSigninSSOurl,
	}
}
